import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

const StyledScrollDownButton = styled.button`
  display: flex;
  background-color: transparent;
  width: ${props => (props.isMobile ? "64px" : "84px")};
  height: ${props => (props.isMobile ? "64px" : "84px")};
  justify-content: center;
  align-items: center;
  opacity: 0.32;
  transition: all 300ms ease;
  cursor: pointer;
  border-radius: 50%;

  .btn-ico {
    display: block;
    width: ${props => (props.isMobile ? "48px" : "64px")};
    height: ${props => (props.isMobile ? "48px" : "64px")};
    line-height: ${props => (props.isMobile ? "48px" : "64px")};

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.64;
  }
`

const ScrollDownButton = ({ clickHandler, isMobile }) => {
  return (
    <StyledScrollDownButton
      id="scroll-down"
      className="overlay-btn ico-button scroll-down-btn"
      onClick={clickHandler}
      isMobile={isMobile}
    >
      <span aria-label="Scroll to content" className="btn-ico">
        <FontAwesomeIcon
          icon={["fas", "chevron-down"]}
          size={isMobile ? "3x" : "4x"}
          color="white"
        />
      </span>
    </StyledScrollDownButton>
  )
}

export default ScrollDownButton
