import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

const StyledPlayButton = styled.button`
  display: flex;
  background-color: transparent;
  width: ${props => (props.isMobile ? "64px" : "128px")};
  height: ${props => (props.isMobile ? "64px" : "128px")};
  justify-content: center;
  align-items: center;
  opacity: 0.32;
  transition: all 300ms ease;
  cursor: pointer;

  .btn-ico {
    display: block;
    width: ${props => (props.isMobile ? "40px" : "80px")};
    height: ${props => (props.isMobile ? "40px" : "80px")};
  }

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.64;
  }
`

const PlayButton = ({ isPaused, clickHandler, isMobile }) => {
  return (
    <StyledPlayButton
      id="vid-pause"
      className="overlay-btn ico-button"
      onClick={clickHandler}
      isMobile={isMobile}
    >
      <span aria-label={isPaused ? "Paused" : "Pause"} className="btn-ico">
        {isPaused ? (
          <FontAwesomeIcon
            icon={["far", "play-circle"]}
            size={isMobile ? "3x" : "6x"}
            color="white"
          />
        ) : (
          <FontAwesomeIcon
            icon={["far", "pause-circle"]}
            size={isMobile ? "3x" : "6x"}
            color="white"
          />
        )}
      </span>
    </StyledPlayButton>
  )
}

export default PlayButton
