import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 10px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${props => props.theme.fontSize.base};
    color: ${props => props.theme.bodyTextColor};
    font-family: ${props => props.theme.fontFamily.sans};
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  button {
  appearance: none;
  border: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden],
  template {
    display: none;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em;
    padding: 0;
    line-height: ${props => props.theme.defaultHeaderLineHeight};
    font-weight: 600;
  }

  p, ul, ol, address {
    margin: 0 0 1em;
    padding: 0;
    line-height: ${props => props.theme.defaultBodyLineHeight};
  }

  main {
    @media (min-width: ${props => props.theme.breakpoints.l}px) {
      min-height: calc(100vh - 450px);
    }
  }

sub,
sup {
  font-size: 64%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

  address {
    font-style: normal;
  }

  /* Utils */

  /* responsive video player */

.player-frame {
  text-align: center;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 auto ${props => props.theme.defaultSpacing};

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.hairline {
  display: block;
  border: 0;
  height: 0;
  width: auto;
  margin: 0;
  clear: both;
  border-bottom: 1px solid ${props => props.theme.ltGreen1};

  &.green1 {
    border-color: ${props => props.theme.brand.green};
  }

  &.dotted {
    border-style: dotted;
  }
}

a:link, a:active, a:visited {
  color: ${props => props.theme.button.inlineLinkColor};
  text-decoration: none !important;
}

a:hover {
  color: ${props => props.theme.button.inlineLinkColorFocus};
}

blockquote {
  color: ${props => props.theme.grey1};
  border-left: 3px solid ${props => props.theme.ltBrown};
  margin: 0 0 ${props => props.theme.defaultSpacing};
  padding-left: ${props => props.theme.defaultSpacing};
}

.content-wrap {
  a:link:not(.btn-link), a:link:not(.line-link) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

// button link
// href, variant, labelText, color, size
.btn-link, .btn-link:link, .btn-link:active, .btn-link:visited {
  display: inline-block;
  font-size: ${props => props.theme.fontSize.base};
  line-height: ${props => props.theme.button.height}px;
  padding: 0 ${props => props.theme.spacing.space4};
  border-radius: ${props => (props.theme.button.height + 4) / 2}px;
  border: 2px solid ${props => props.theme.button.bgColor};
  background-color: ${props => props.theme.button.bgColor};
  color: ${props => props.theme.button.labelColor};
  font-weight: 600;
  transition: all 300ms ease;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  min-width: 180px;
  cursor: pointer;

  &:hover, &:focus {
    background-color: ${props => props.theme.button.bgColorFocus};
    border-color: ${props => props.theme.button.bgColorFocus};
  }

  // modifiers
  &.btn-trace {
    background-color: transparent;
    border-color: ${props => props.theme.button.bgColor};

    &:hover, &:focus {
      background-color: ${props => props.theme.button.bgColorTransparent};
    }

    &.reverse-trace {
      color: ${props => props.theme.button.bgColorFocus};

      &:hover, &:focus {
        color: ${props => props.theme.button.labelColor};
        background-color: ${props => props.theme.button.bgColorFocus};
      }

    }
  }
}

// line-link

a.line-link {
  display: inline-block;
  text-decoration: none!important;
  line-height: 1.5;
  color: ${props => props.theme.bodyTextColor};
  border-bottom: 1px dotted ${props => props.theme.green0};
  transition: all 300ms ease;

  &:hover, &:focus {
    color: ${props => props.theme.green0};
  }
}

h1 {
  font-size: 3.2rem;
  font-family: ${props => props.theme.fontFamily.sans};
}

h2 {
  color: ${props => props.theme.grey3};
  font-size: ${props => props.theme.fontSize.h3};
  font-family: ${props => props.theme.fontFamily.sans};
}

h3 {
  color: ${props => props.theme.grey3};
  font-size: 1.8rem;
}

h4 {
  font-size: 1.8rem;
  font-family: ${props => props.theme.fontFamily.sans};
}

@media (min-width: ${props => props.theme.breakpoints.m}px) {
  h1 {
    font-size: ${props => props.theme.fontSize.h1};
  }

  h2 {
    font-size: ${props => props.theme.fontSize.h2};
  }

  h3 {
    font-size: ${props => props.theme.fontSize.h3};
  }
}

// tools
.mx-2 {
  margin: 0 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.text-unit {
  display: inline-block;
  white-space: nowrap;
}

.m--text-unit {
  @media (min-width: ${props => props.theme.breakpoints.l}px) {
    display: inline-block;
    white-space: nowrap;
  }
}

.upper {
  text-transform: uppercase;
}

.ts-s {
  font-size: ${props => props.theme.fontSize.s};
}

.ts-l {
  font-size: ${props => props.theme.fontSize.l};
}

.xs--dn {
  @media (max-width: ${props => props.theme.breakpoints.s}px) {
    display: none;
  }
}

.db {
  display: block;
}

.max-640 {
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.theme.maxWidthCopy};
}

.tf-sans {
  font-family: ${props => props.theme.fontFamily.sans}
}

.t-alignC {
  text-align: center;
}

.c-grey1 {
  color: ${props => props.theme.grey1};
}

// img

.fit {
  display: block;
  width: 100%;
}

// LISTS

.list-bullet,
.list-decimal,
.list-low-alpha,
.list-plain {
  padding-left: 2rem;
  line-height: ${props => props.theme.defaultBodyLineHeight};
  margin-bottom: ${props => props.theme.defaultSpacing};

  li {
    margin-bottom: 0.5em;
  }

  li {
    &:last-of-type,
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-bullet {
  list-style: disc outside;
}

.list-decimal {
  list-style: decimal outside;
}

.list-low-alpha {
  list-style: lower-alpha outside;
}

.list-plain {
  list-style: none;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.divider {
  display: inline-block;
  width: 2em;

  &.compact {
    width: 1em;
  }
}
`

export default GlobalStyles
