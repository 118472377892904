const smiXPayperksTheme = {
  bgPage: "#FFF",
  bodyTextColor: "#111212",
  brand: {
    clear: "transparent",
    green: "#00C188",
    greenTransparent: "rgba(0,193,136,0.48)",
    blackTransparent: "rgba(17,18,18,0.64)",
    blue: "#2E70FD",
    blue0: "#CDDDFF",
    blue1: "#1F4FB8",
    light0: "#F1F5FD",
    dark0: "#222323",
    dark1: "#111212",
    gold0: "#F8E5A2",
    gold1: "#f9cb4c",
    green1: "#168A68",
    green2: "#0B4638",
    grey0: "#FAFAFA",
    grey1: "#F4F4F4",
    grey2: "#E0E0E0",
    grey3: "#CFD0D0",
    grey4: "#888",
    ltGold0: "#f4d47a",
    ltGold1: "#fff4e0",
    ltGreen0: "#c7dbd8",
    white: "#FFF",
    whiteTranslucent: "rgba(255, 255, 255, 0.48)",
  },
  black: "#000",
  blackTransparent: "rgba(0,0,0,0.64)",
  brown: "#8c8981",
  ltBrown: "#c1b8a1",
  gold0: "#f9cb4c",
  green0: "#4e9b45",
  green1: "#55b64b",
  green1Transparent: "rgba(85,182,75,0.48)",
  green2: "#2f6f37",
  grey0: "#f0f0f0",
  grey1: "#818c8a",
  grey2: "#666",
  grey3: "#222",
  ltGold0: "#f4d47a",
  ltGold1: "#fff4e0",
  ltGreen0: "#dfecd6",
  ltGreen1: "#cce1be",
  tan: "#f8f4ed",
  white: "#fff",
  whiteTransparent: "rgba(255,255,255,0.64)",
  maxWidth: "1200px",
  maxWidthCopy: "720px",
  maxWidthOneThirdCol: "540px",
  breakpoints: {
    l: 960,
    m: 768,
    s: 480,
  },
  defaultSpacing: "2rem",
  defaultGridGap: "3rem",
  defaultSectionSpacing: "8rem",
  defaultSectionSpacingMobile: "3rem",
  defaultImgMaxWidth: "400px",
  fontFamily: {
    sans: "Raleway, sans-serif",
    serif: "'PT Serif', serif",
  },
  spacing: {
    space1: "5px",
    space2: "10px",
    space3: "15px",
    space4: "20px",
    space5: "30px",
    space6: "60px",
  },
  defaultBodyLineHeight: 1.6,
  defaultHeaderLineHeight: 1.25,
  borderRad: "10px",
  borderRadCompact: "5px",
  boxShadow: "0 1px 3px 1px hsla(0,0%,40%,.24)",
  boxShadowHover: "0 1px 6px 1px hsla(0,0%,40%,.48)",
  boxShadowBlue: "0 1px 20px 0 rgba(46, 112, 253, 0.25)",
  boxShadowBlueHover: "0 1px 20px 0 rgba(46, 112, 253, 0.5)",
  fontSize: {
    xs: "1.2rem",
    s: "1.4rem",
    base: "1.75rem",
    l: "2rem",
    h1: "5rem",
    h2: "3.6rem",
    h3: "2.8rem",
    h4: "2.4rem",
    h5: "2.2rem",
  },
  button: {
    height: 40,
    bgColor: "#00C188",
    bgColorFocus: "#168A68",
    bgColorTransparent: "rgba(0,193,136,0.48)",
    bgColorBlue: "#2E70FD",
    bgColorBlueFocus: "#1F4FB8",
    labelColor: "#FFF",
    inlineLinkColor: "#00C188",
    inlineLinkColorFocus: "#168A68",
  },
  uiCardWidth: { min: 210, max: 280 },
}

export { smiXPayperksTheme }
