// Scroll to element
const scrollToRef = (ref, extraOffsetTop, useSmoothScroll) => {
  const offsetTopDiff = extraOffsetTop || 0
  const useSmooth = useSmoothScroll || true

  if (useSmooth) {
    window.scrollTo({
      top: ref.current.offsetTop + offsetTopDiff,
      left: 0,
      behavior: "smooth",
    })
  } else {
    window.scrollTo(0, ref.current.offsetTop + offsetTopDiff)
  }
}

export { scrollToRef }
