import React, { useLayoutEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

import PlayButton from "./player/PlayButton"
import ScrollDownButton from "./nav/ScrollDownButton"
import PayPerksLoopWebM from "../images/PayPerks_Loop.webm"
import PayPerksLoopMP4 from "../images/PayPerks_Loop.mp4"
import DawnPoster from "../images/poster-dawn.jpg"

const StyledHeroArea = styled.div`
  position: relative;
  height: calc(100vh - 52px);
  background: white;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.l}px) {
    height: 60vh;
  }

  .hero-main {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    #bg-vid {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      background: url(${DawnPoster}) no-repeat;
      background-size: cover;
    }

    .hero-overlay {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      top: 0;
      left: 0;
      background: ${props => props.theme.brand.blackTransparent};
      z-index: 1;
      justify-content: center;
      align-items: center;
      color: ${props => props.theme.brand.white};

      .splash-message {
        width: 90%;

        text-align: center;

        h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        .hairline {
          margin: 1rem auto;
          border-color: ${props => props.theme.brand.green};
        }

        p {
          font-size: ${props => props.theme.fontSize.s};
          color: ${props => props.theme.brand.ltGreen0};
          font-weight: 500;
        }

        // responsive
        @media (min-width: ${props => props.theme.breakpoints.l + 1}px) {
          width: 710px;

          h1 {
            font-size: 5.6rem;
          }

          .hairline {
            margin: 1.5rem auto;
          }

          p {
            font-size: ${props => props.theme.fontSize.l};
          }
        }

        @media (min-width: ${props =>
            props.theme.breakpoints.s}px) and (max-width: ${props =>
            props.theme.breakpoints.l}px) {
          width: 76%;

          h1 {
            font-size: 4rem;
          }

          .hairline {
            margin: 1rem auto;
          }

          p {
            font-size: ${props => props.theme.fontSize.base};
          }
        }
      }
    }
  }

  #vid-pause {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .scroll-down-btn {
    display: none;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    margin-bottom: ${props => (props.isMobile ? "1rem" : "2rem")};
    margin-left: ${props => (props.isMobile ? "-32px" : "-42px")};

    @media (min-width: ${props => props.theme.breakpoints.m}px) {
      display: block;
    }
  }

  .hero-cta {
    display: none;
    margin-top: ${props => props.theme.defaultSectionSpacingMobile};

    a {
      margin: 0 1.5rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.m}px) {
      display: block;
    }
  }
`

const HeroArea = ({
  variant,
  scrollToBtnClickHandler,
  isMobile,
  prefersReducedMotion,
  isHeroInViewport,
}) => {
  const [isFirstView, setIsFirstView] = useState(true)
  const [isVideoPaused, setIsVideoPaused] = useState(true)

  const heroVideoPlayerRef = useRef(null)

  const playPauseToggleHandler = () => {
    if (isVideoPaused) {
      heroVideoPlayerRef.current.play()
      setIsVideoPaused(false)
    } else {
      heroVideoPlayerRef.current.pause()
      setIsVideoPaused(true)
    }
  }

  useLayoutEffect(() => {
    if (isFirstView) {
      if (!prefersReducedMotion && !isMobile && isHeroInViewport) {
        heroVideoPlayerRef.current.play()
        setIsVideoPaused(false)
        setIsFirstView(false)
      }
    }

    if (!isHeroInViewport || isMobile) {
      heroVideoPlayerRef.current.pause()
      setIsVideoPaused(true)
    }
  }, [isHeroInViewport, isMobile, prefersReducedMotion, isFirstView])

  return (
    <StyledHeroArea className="has-hero" variant={variant} isMobile={isMobile}>
      <div className="hero-main">
        <video
          ref={heroVideoPlayerRef}
          playsInline
          autoPlay={!prefersReducedMotion && !isMobile && isHeroInViewport}
          muted
          loop
          poster={DawnPoster}
          id="bg-vid"
        >
          <source src={PayPerksLoopMP4} type="video/mp4" />
          <source src={PayPerksLoopWebM} type="video/webm" />
        </video>
        <div className="hero-overlay">
          <div className="splash-message">
            <h1 className="hero-header">Education, Meet Rewards</h1>
            <hr className="hairline" />
            <p className="hero-desc">
              PayPerks provides integrated education and rewards solutions{" "}
              <span className="text-unit">
                to help improve cardholders&rsquo;
              </span>{" "}
              financial literacy and capability.
            </p>
            <div className="hero-cta">
              <button className="btn-link" onClick={scrollToBtnClickHandler}>
                Learn More{" "}
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  size="1x"
                  color="white"
                />
              </button>
            </div>
          </div>
          <PlayButton
            isPaused={isVideoPaused}
            clickHandler={playPauseToggleHandler}
            isMobile={isMobile}
          />
          <ScrollDownButton
            clickHandler={scrollToBtnClickHandler}
            isMobile={isMobile}
          />
        </div>
      </div>
    </StyledHeroArea>
  )
}

export default HeroArea
