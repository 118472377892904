import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import LogoImg from "../images/logo-payperks.svg"
import LogoImgWhite from "../images/logo-payperks-white.svg"
import PeteBigArms from "../images/pete/pete-big-arms.png"
import { HOME_URL, PAYPERKS_DE_TRANSITION_URL } from "../constants/pageUrls"

const StyledHeader = styled.header`
  width: 100%;
  background-color: ${props =>
    props.variant === "hero" && props.isHeroInViewport
      ? "transparent"
      : props.theme.brand.white};
  box-shadow: ${props =>
    (props.variant === "hero" && props.isHeroInViewport) ||
    (props.variant === "default" && !props.isBodyScrollingUp)
      ? "none"
      : props.theme.boxShadow};
  position: fixed;
  z-index: ${props => (props.isNavOpen ? 2 : 5)};

  .flex-header {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 1rem ${props => props.theme.defaultSpacing};

    .global-nav-wrapper {
      margin-left: ${props => props.theme.defaultSpacing};
      flex: 1;

      // mobile nav toggle
      @media (max-width: ${props => props.theme.breakpoints.m}px) {
        display: flex;
        justify-content: flex-end;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.l}px) {
      padding: 1rem 4rem;
    }
  }
`

const HomeLogoLink = styled.div`
  display: block;
  width: 120px;
  height: 22px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}px) {
    width: 180px;
    height: 33px;
  }
`

const PeteDirectExpressCallout = styled.div`
  padding: 0.5rem ${props => props.theme.defaultSpacing};
  background: ${props => props.theme.brand.dark1};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.brand.white};
  font-weight: 600;
  justify-content: center;

  .img-frame.tiny-frame {
    margin-right: 1rem;
    .ico-pete-ring {
      display: block;
      width: 42px;
      height: 42px;
      background: url(${PeteBigArms}) no-repeat transparent center center;
      background-size: contain;
    }
  }

  p {
    margin: 0;
    line-height: 1.2;
    font-size: 0.8em;

    a {
      color: ${props => props.theme.brand.green};
    }

    @media (min-width: ${props => props.theme.breakpoints.s}px) {
      font-size: 0.9em;
    }
  }
`

const Header = ({
  siteTitle,
  variant,
  isHeroInViewport,
  isBodyScrollingUp,
  isMobile,
  isNavOpen,
  isOnTransitionPage,
}) => (
  <StyledHeader
    variant={variant}
    isHeroInViewport={isHeroInViewport}
    isBodyScrollingUp={isBodyScrollingUp}
    isMobile={isMobile}
    isNavOpen={isNavOpen}
  >
    <PeteDirectExpressCallout className="cta-payperks-for-de">
      <div className="img-frame tiny-frame">
        <span className="ico-pete-ring" />
      </div>
      <p>
        PayPerks for Direct Express<sup>&reg;</sup> has ended.{" "}
        {!isOnTransitionPage && (
          <Link
            to={PAYPERKS_DE_TRANSITION_URL}
            className="learn-more-link text-unit"
          >
            Learn more &rarr;
          </Link>
        )}
      </p>
    </PeteDirectExpressCallout>
    <div className="inner flex-header">
      <Link to={HOME_URL} className="home-logo-link">
        <HomeLogoLink>
          <img
            src={
              variant === "hero" && isHeroInViewport ? LogoImgWhite : LogoImg
            }
            alt={siteTitle}
          />
        </HomeLogoLink>
      </Link>
      {/* Global Nav */}
    </div>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
