import React, { useLayoutEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faPlayCircle,
  faPauseCircle,
} from "@fortawesome/free-regular-svg-icons"
import {
  faArrowCircleRight,
  faBullhorn,
  faChevronDown,
  faHeart,
  faBars,
  faTimes,
  faMapMarkerAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import {
  faAccessibleIcon,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { Waypoint } from "react-waypoint"
import debounce from "lodash/debounce"

import Header from "./header"
import GlobalStyles from "./styles/GlobalStyles"
import { smiXPayperksTheme } from "../constants/smiXPayperksTheme"
import HeroArea from "./HeroArea"
import FooterSmiPayPerks from "./FooterSmiPayPerks"
import { scrollToRef } from "../utils/domUtils"

library.add(
  faPlayCircle,
  faPauseCircle,
  faArrowCircleRight,
  faBullhorn,
  faChevronDown,
  faHeart,
  faMapMarkerAlt,
  faAccessibleIcon,
  faBars,
  faTimes,
  faFacebook,
  faLinkedin,
  faTwitter,
  faExclamationTriangle
)

const StyledPageWrap = styled.div`
  background: ${props => props.theme.bgPage};

  .page {
    position: relative;
    height: ${props => (props.isMobileNavOpen ? "100vh" : "auto")};
    overflow: ${props => (props.isMobileNavOpen ? "hidden" : "auto")};
  }

  .header-spacing {
    display: block;
    height: ${props => (props.variant === "hero" ? "52px" : "105px")};
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .page-dim-overlay {
    position: ${props =>
      props.isMobile && props.isMobileNavOpen ? "fixed" : "absolute"};
    display: ${props =>
      props.isMobile && props.isMobileNavOpen ? "block" : "none"};
    width: 100%;
    height: 100vh;
    background: ${props => props.theme.black};
    opacity: ${props => (props.isMobileNavOpen ? 0.48 : 0)};
    z-index: 5;
    transition: opacity 300ms ease;
  }

  // Mobile Nav
  .global-nav-overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: ${props => props.theme.defaultSpacing};
    background: ${props => props.theme.brand.grey0};
    z-index: 7;

    @media (min-width: ${props => props.theme.breakpoints.s}px) {
      width: 300px;
    }
  }
`

export const InnerPageWrap = styled.div`
  width: 90%;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 3rem 0;

  p {
    max-width: ${props => props.theme.maxWidthCopy};
  }

  .intro-p {
    font-size: ${({ theme }) => theme.fontSize.l};
    line-height: ${({ theme }) => theme.defaultBodyLineHeight};
  }

  .p-body {
    font-size: ${props => props.theme.fontSize.base};
    font-family: ${props => props.theme.fontFamily.serif};
    line-height: ${props => props.theme.defaultBodyLineHeight};
  }

  @media (min-width: ${props => props.theme.breakpoints.l}px) {
    padding: 6rem 0;

    .intro-p {
      font-size: ${({ theme }) => theme.fontSize.h5};
    }
  }
`

const Layout = ({ children, variant }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const pageRootRef = useRef(null)
  const pageContentRef = useRef(null)

  const [isHeroInViewport, setIsHeroInViewport] = useState(true)
  const [isBodyScrollingUp, setIsBodyScrollingUp] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [viewWidth, setViewWidth] = useState(0)
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false)

  // Waypoints
  const onWaypointEnter = () => {
    setIsHeroInViewport(true)
  }

  const onWaypointLeave = () => {
    setIsHeroInViewport(false)
  }

  // Non-hero pages
  const onHeaderSpcWaypointEnter = () => {
    setIsBodyScrollingUp(false)
  }

  const onHeaderSpcWaypointLeave = () => {
    setIsBodyScrollingUp(true)
  }

  // UI events
  const scrollToContent = () => {
    scrollToRef(pageContentRef, -50, true)
  }

  const onWindowResize = () => {
    const viewW = window.innerWidth

    if (viewW !== viewWidth) {
      setViewWidth(viewW)
      setIsMobile(viewW < smiXPayperksTheme.breakpoints.m)
    }

    if (window.matchMedia("(prefers-reduced-motion)").matches) {
      setPrefersReducedMotion(true)
    }
  }

  const debouncedOnWindowResize = debounce(onWindowResize, 250, {
    trailing: true,
  })

  useLayoutEffect(() => {
    onWindowResize()
    window.addEventListener("resize", debouncedOnWindowResize)

    return () => {
      window.removeEventListener("resize", debouncedOnWindowResize)
    }
  }, [])

  return (
    <ThemeProvider theme={smiXPayperksTheme}>
      <GlobalStyles />
      <StyledPageWrap variant={variant || "default"} isMobile={isMobile}>
        <div className="page" ref={pageRootRef}>
          <div className="page-dim-overlay" />
          <Header
            siteTitle={data.site.siteMetadata.title}
            variant={variant || "default"}
            isHeroInViewport={isHeroInViewport}
            isBodyScrollingUp={isBodyScrollingUp}
            isMobile={isMobile}
            isOnTransitionPage={variant === "transition"}
          />
          {variant !== "hero" ? (
            <Waypoint
              scrollableAncestor={"window"}
              onEnter={onHeaderSpcWaypointEnter}
              onLeave={onHeaderSpcWaypointLeave}
              topOffset={103}
            >
              <div className="header-spacing" />
            </Waypoint>
          ) : (
            <div className="header-spacing" />
          )}
          {variant === "hero" && (
            <Waypoint
              scrollableAncestor={"window"}
              onEnter={onWaypointEnter}
              onLeave={onWaypointLeave}
              topOffset={51}
            >
              <div>
                <HeroArea
                  variant="hero"
                  scrollToBtnClickHandler={scrollToContent}
                  isMobile={isMobile}
                  prefersReducedMotion={prefersReducedMotion}
                  isHeroInViewport={isHeroInViewport}
                />
              </div>
            </Waypoint>
          )}
          <InnerPageWrap ref={pageContentRef}>
            <main>{children}</main>
          </InnerPageWrap>
          <FooterSmiPayPerks />
        </div>
      </StyledPageWrap>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
