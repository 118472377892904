import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import LogoSMI from "../images/smi/logo-smi-white.svg"
import LogoSMIOne from "../images/smi/logo-smiONE-white.png"
import LogoPayPerks from "../images/logo-payperks-white.svg"
import {
  SMI_CORP_URL,
  SMI_ONE_CARD_HOME_URL,
  LEGAL_PRIVACY_URL,
  LEGAL_TERMS_URL,
} from "../constants/pageUrls"

const FooterSmiPayPerksStyles = styled.footer`
  background: ${props => props.theme.brand.dark1};
  color: ${props => props.theme.brand.grey3};
  font-size: ${props => props.theme.fontSize.s};

  a:link,
  a:visited,
  a:active {
    color: ${props => props.theme.brand.light0};
    font-weight: 600;
  }

  a:hover {
    color: ${props => props.theme.brand.green};
  }

  .logo-footer {
    display: block;
    width: 148px;
    height: 28px;

    &.logo-payperks {
      background: url(${LogoPayPerks}) no-repeat transparent center center;
      background-size: contain;
    }

    &.logo-smi {
      width: 64px;
      background: url(${LogoSMI}) no-repeat transparent center center;
      background-size: contain;
      display: inline-block;
      height: 24px;
    }

    &.logo-smi-one {
      width: 96px;
      background: url(${LogoSMIOne}) no-repeat transparent center center;
      background-size: contain;
      display: inline-block;
      margin-left: ${props => props.theme.spacing.space2};
    }
  }

  .footer-footer {
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 500;

    // Footer footer
    .copy {
      flex: 0 0 480px;
    }

    .copyright {
      color: ${props => props.theme.brand.white};
      font-weight: 600;
    }

    .smi-info {
      display: flex;
      flex: 0 1 320px;
      color: ${props => props.theme.brand.grey4};
      margin-left: ${props => props.theme.spacing.space4};
      justify-content: flex-end;
    }

    @media (max-width: ${props => props.theme.breakpoints.m}px) {
      display: block;

      .smi-info {
        margin-top: ${props => props.theme.defaultSectionSpacingMobile};
        margin-left: 0;
        justify-content: center;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.l}px) {
      padding: 4rem;

      .copy {
        flex-basis: 600px;
      }
    }
  }
`

const FooterSmiPayPerks = () => {
  return (
    <FooterSmiPayPerksStyles>
      <div className="footer-footer">
        <div className="copy">
          <div className="logo-footer logo-payperks mb-1" />
          <p className="fine-text">
            The PayPerks education and rewards solution to help cardholders
            develop financial capability is brought to you by{" "}
            <a href={SMI_CORP_URL} target="_blank" rel="noopener noreferrer">
              SMI
            </a>
            . To learn more, email{" "}
            <a href="mailto:inquiries@smimail.net">inquiries@smimail.net</a>.
          </p>
          <p className="copyright">
            <strong>
              © {new Date().getFullYear()} Systems &amp; Methods, Inc.
            </strong>{" "}
            All rights reserved.
          </p>
          <p className="mb-0">
            <Link to={LEGAL_TERMS_URL}>Terms of Service</Link>
            <span className="divider compact" />
            <Link to={LEGAL_PRIVACY_URL}>Privacy Policy</Link>
          </p>
        </div>
        <div className="smi-info">
          <div>
            <a href={SMI_CORP_URL} target="_blank" rel="noopener noreferrer">
              <div className="logo-footer logo-smi" />
            </a>
            <a
              href={SMI_ONE_CARD_HOME_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="logo-footer logo-smi-one" />
            </a>
          </div>
        </div>
      </div>
    </FooterSmiPayPerksStyles>
  )
}

export default FooterSmiPayPerks
